body {
  margin: 0;

  background-color: #f5f5fa;

  background-color: #f5f5fa;
}

.loading {
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: absolute;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: table;
  background-color: grey;
  opacity: 0.5;
}

.spanImg {
  display: table-cell;
  vertical-align: middle;
}
.imgloading {
  width: 50px;
}

.otp-field {
  width: 40px;
  margin-top: 40px;
  margin-right: 10px;
  padding-left: 12px;
  height: 40px;
  border: 1px solid rgb(186, 189, 192);
  border-radius: 5px;
  opacity: 1;
}

/* form {
  background: rgb(0,130,255);
  background: linear-gradient(180deg, rgba(0,130,255,1) 0%, rgba(57,41,234,1) 100%);
} */
